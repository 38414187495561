import React from "react";
import "./news.scss";
/*global FB*/

function News() {

  React.useEffect(() => {
    if (window.FB) {
      FB.XFBML.parse();
    }
  }, []);

  return (
    <div className="News" id="news">
      <div className="grid-item news-block">
        <h2>Notre actualité</h2>
        <div className="fb-page"
          data-href="https://www.facebook.com/profile.php?id=100087422290198"
          data-tabs="timeline,events"
          data-width="500px"
          data-height="900px"
          data-small-header="true"
          data-adapt-container-width="true"
          data-hide-cover="true"
          data-show-facepile="true">
        </div>
      </div>
    </div>
  );
}

export default News;
