import React from "react";
import "./contact.scss";
import image from "../../assets/contact-image.jpg";
import { MdOutlineMail } from "react-icons/md";
import LinkButton from "components/link-button/LinkButton";
import SocialsLinks from "components/socials-links/SocialsLinks";

function Contact() {
  return (
    <div className="Contact" id="contact">
      <div className="grid-item image-block contact-img" style={{ backgroundImage: `url(${image})` }}></div>
      <div
        className="grid-item content-block"
        data-aos="fade-right"
        data-aos-duration="800"
      >
        <h2>Nous contacter</h2>
        <LinkButton
          text={"ENVOYER UN MAIL"}
          icon={<MdOutlineMail></MdOutlineMail>}
          link={"mailto:assoparer@outlook.fr"}
        ></LinkButton>
        <div className="socials-links-wrapper">
          <p>ou sur nos réseaux :</p>
          <SocialsLinks></SocialsLinks>
        </div>
      </div>
    
    </div>
  );
}

export default Contact;
