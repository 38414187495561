import Navbar from "components/navbar/Navbar";
import React from "react";
import "./footer.scss";

function Footer() {
  return (
    <div className="Footer">
      <Navbar mainNav={false}></Navbar>
    </div>
  );
}

export default Footer;
