import React from "react";
import "./presentation.scss";
import image from "../../assets/presentation-image.jpg";

function Presentation() {
  return (
    <div className="Presentation" id="association">
      <div className="grid-item" data-aos="fade-left" data-aos-duration="800" data-aos-delay="800">
        <div className="title-block content-block">
          <h3>L'association</h3>
          <h2>P.a.r.e.r.</h2>
          <p className="subtitle">
            “Scolariser les enfants <br /> et les adolescents vulnérables”
          </p>
        </div>

        <div className="presentation-block content-block justify">
          <p>
            L’association PARER a été créée le 20 janvier 2014 à l’initiative de
            David Roux, éducateur spécialisé, chef de service éducatif, pour
            l’association CODASE à Grenoble, Antoine Gentil, professeur des
            écoles spécialisé, coordonnateur du Dispositif STARTER, au Lycée
            Guynemer, à Grenoble.
          </p>
          <p>
            Alors que la société dans son ensemble appelle à l’action contre le
            décrochage scolaire, que les prises de parole sur le sujet sont
            nombreuses et variées, l’association PARER propose la construction
            d’un discours cohérent sur les parcours scolaires des adolescents et
            des enfants vulnérables qui sont tous des "enfants de la
            République".
          </p>
        </div>
      </div>

      <div className="grid-item image-block" style={{ backgroundImage: `url(${image})` }}></div>
    </div>
  );
}

export default Presentation;
