import React, { useState } from "react";
import { FaBars, FaAngleDown } from "react-icons/fa";
import "./navbar.scss";
import logo from "../../assets/logo.png";
import SocialsLinks from "components/socials-links/SocialsLinks";

function Logo({ light = false }) {
  return (
    <div className="logo">
      {light ? (
        <img src={logo} alt="Logo association Parer" className="footer-nav"></img>
      ) : (
        <img src={logo} alt="Logo association Parer"></img>
      )}
    </div>
  );
}

function MenuLinks({onLinkClick}) {
  return (
    <nav>
      <a className="nav-link" href="#association" onClick={(event) => onLinkClick(event)}>
        L'association
      </a>
      <a className="nav-link" href="#actions" onClick={(event) => onLinkClick(event)}>
        Nos actions
      </a>
      <a className="nav-link" href="#team" onClick={(event) => onLinkClick(event)}>
        L'équipe
      </a>
      <a className="nav-link" href="#contact" onClick={(event) => onLinkClick(event)}>
        Contact
      </a>
      <a className="nav-link" href="#news" onClick={(event) => onLinkClick(event)}>
        Actualité
      </a>
      <a className="nav-link" href="#film" onClick={(event) => onLinkClick(event)}>
        Le film
      </a>
    </nav>
  );
}

function Navbar({ mainNav = true }) {
  const [toggleMenu, setToggleMenu] = useState(false);

  const onLinkClick = (clickEvent) => {
    setToggleMenu(false)
  }

  return (
    <>
        <Logo light={!mainNav} />
      <div
        className="wrapper-nav desktop-nav"
        style={mainNav ? { color: "black" } : { color: "white" }}
      >
          <div className="wrapper-links">
              <MenuLinks/>
              <SocialsLinks />
          </div>

      </div>

      <div className="wrapper-nav mobile-nav">
        { mainNav && (
          <div
            className="mobile-menu-icon"
            onClick={() => setToggleMenu(!toggleMenu)}
          >
            {toggleMenu ? <FaAngleDown /> : <FaBars />}
          </div>
        )}
        

        {toggleMenu && mainNav && (
          <>
            <div className="mobile-menu">
              <MenuLinks onLinkClick={(clickEvent) => onLinkClick(clickEvent)}/>
              <SocialsLinks />
            </div>
            <div
              className="mobile-menu-overlay"
              onClick={() => setToggleMenu(false)}
            ></div>
          </>
        )}
      </div>
    </>
  );
}

export default Navbar;
