import React from "react";
import "./linkButton.scss";

function LinkButton({ text, icon, link }) {
  return (
    <a
      className="LinkButton"
      href={link ? link : null}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span className="text">{text}</span>
      <span className="icon">{icon}</span>
    </a>
  );
}

export default LinkButton;
