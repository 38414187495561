import React from "react";
import "./socialsLinks.scss";
import { FaFacebook, FaLinkedin } from "react-icons/fa";

function SocialsLinks() {
  return (
    <div className="SocialsLinks">
      <a
        className="nav-link social-icon"
        href="https://www.facebook.com/profile.php?id=100087422290198"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaFacebook />
      </a>
      <a
        className="nav-link social-icon"
        href="https://www.linkedin.com/company/p-a-r-e-r/about/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaLinkedin />
      </a>
    </div>
  );
}

export default SocialsLinks;
