import React from "react";
import { MdArrowForward } from "react-icons/md";
import "./videoPlayer.scss";
import affiche from "../../assets/affiche-film.jpg";
import interview from "../../assets/interview.jpg";
import interviewPdf from "../../assets/interview.pdf";
import LinkButton from "components/link-button/LinkButton";

function VideoPlayer() {
  return (
    <div className="VideoPlayer" id="film">
      <div className="grid-item affiche-block" style={{ backgroundImage: `url(${affiche})` }}>
      </div>
      <div
        className="grid-item"
        data-aos="fade-right"
        data-aos-duration="800"
      >
        <div
          className="title-block content-block"
          data-aos="fade-up"
          data-aos-duration="800"
        >
          <h3>Le film</h3>
          <h2>Un bon début</h2>
          <p className="subtitle">UN DOCUMENTAIRE DE</p>
          <p>Agnès Molia et Xabi Molia</p>
        </div>
        <div className="synopsis-block content-block justify">
          <p className="synopsis">
            Ils ont l’âge d’entrer en troisième et déjà une réputation
            d’irrécupérables. Pendant des mois, ils ont vécu loin du collège, en
            rupture presque totale avec la vie scolaire. À Grenoble, une classe
            unique en France du nom de « Starter » leur ouvre ses portes.
            Pendant cette année particulière, Un bon début suit leur
            adolescence, fragile et malmenée – mais dont le cours peut encore
            changer.
          </p>
          <LinkButton
            text={"VOIR LA BANDE ANNONCE"}
            icon={<MdArrowForward></MdArrowForward>}
            link={"https://www.hautetcourt.com/documentaires/un-bon-debut/"}
          ></LinkButton>
        </div>
      </div>

      <div
        className="grid-item interview-block content-block"
        data-aos="fade-left"
        data-aos-duration="800"
      >
        <h4>
          Comment est né <br /> Un bon début ?
        </h4>
        <LinkButton
          text={"LIRE L'INTERVIEW"}
          icon={<MdArrowForward></MdArrowForward>}
          link={interviewPdf}
        ></LinkButton>
      </div>
      <div className="grid-item">
        <img
          className="interview-img"
          src={interview}
          alt="Illustration interview"
        ></img>
      </div>
    </div>
  );
}

export default VideoPlayer;
