import React from "react";
import "./team.scss";
import david from "../../assets/david-roux.jpg";
import antoine from "../../assets/antoine-gentil.jpg";
import natacha from "../../assets/natacha-andre.jpg";

function Member({name, fonction, category, text, imageSrc}) {
  return(
    <div className="Member">
      <div className="grid-item content-block text-block"
        data-aos="fade-left"
        data-aos-duration="800">
        <p className="name">{name}</p>
        <p className="subtitle">{fonction}</p>
        <p className="category">{category}</p>
        <p className="justify">{text}</p>
      </div>
      <div className="grid-item image-block">
      <img data-aos="fade"
          data-aos-duration="800"
          data-aos-delay="600"
          className="member-img"
          src={imageSrc}
          alt="membre equipe"
        ></img>
      </div>
    </div>
  )
}

function Team() {
  return (
    <div className="Team" id="team">
      <Member
      name="David ROUX"
      fonction="Chef de service éducatif"
      category="(Protection de l’Enfance, Association CoDASE)"
      text="David est le porteur du projet PARER. Après des études universitaires scientifiques puis de management culturel, il devient éducateur en 2006 pour le CoDASE puis chef de service éducatif en 2013.
      Impliqué dans plusieurs associations autour de la jeunesse et de l’égalité des chances, il fonde avec Antoine en 2014 l’association P.A.R.E.R. axée sur l’alliance éducative autour de l’école de la République.
      Ils construisent ensemble un mode d’accompagnement éducatif orienté pour les enfants les plus en difficultés : les rattacher scolairement pour les rattacher socialement."
      imageSrc={david}>
      </Member>
      <Member
      name="Antoine GENTIL"
      fonction="Professeur spécialisé"
      category="(Education Nationale)"
      text="Antoine a suivi des études de géographie avant de devenir professeur des écoles en 2000.
      Il a été enseignant à la Maison d’arrêt de Grenoble-Varces puis s’est spécialisé dans la prise en charge de jeunes en situation de décrochage scolaire.
      Il a créé Starter il y a tout juste dix ans, en 2012. Également formateur (entre autres sur les questions de laïcité et de gestion des relations interculturelles),
      cofondateur de l'association P.A.R.E.R. Il réunit des professionnels pour penser les alliances éducatives dans l’accompagnement des enfants et des adolescents vulnérables."
      imageSrc={antoine}>
      </Member>
      <Member
      name="Natacha ANDRE"
      fonction="Educatrice"
      category="(Protection de l’Enfance)"
      text="Natacha est l’attachée de communication du projet P.A.R.E.R. et intervient en tant que bénévole du CoDASE sur le dispositif STARTER.
      Ancienne cadre de l’industrie du sport et du textile, elle rejoint en 2016 les métiers du social et de la jeunesse par choix de reconversion.
      Formée par Antoine au sein du dispositif STARTER et managée par David en MECS, sa place à la croisée des mondes de l’accompagnement, des entreprises et de la communication est aujourd’hui toute trouvée."
      imageSrc={natacha}>
      </Member>
    </div>
  );
}

export default Team;
