import React from 'react'
import Navbar from 'components/navbar/Navbar'
import './header.scss'

function Header() {
  return (
    <div className='Header'>
      <Navbar></Navbar>
    </div>
  )
}

export default Header