import "./App.scss";
import "aos/dist/aos.css";
import AOS from "aos";
import Header from "layouts/header/Header";
import Footer from "layouts/footer/Footer";
import VideoPlayer from "layouts/video-player/VideoPlayer";
import Presentation from "layouts/presentation/Presentation";
import ActionsList from "layouts/actions-list/ActionsList";
import Contact from "layouts/contact/Contact";
import Team from "layouts/team/Team";
import News from "layouts/news/News";

import { FaFacebook } from "react-icons/fa";

function FBPopup() {
  return(
    <a className="FBPopup"
    href="https://www.facebook.com/profile.php?id=100087422290198"
    target="_blank"
    rel="noopener noreferrer">
      <div className="text">
        Restez informé,
        <br/>
        suivez nous sur facebook.
      </div>

      <div className="icon">
        <FaFacebook></FaFacebook>
      </div>
    </a>
  )
}

function App() {
  AOS.init({
    once: true,
    anchorPlacement: "bottom",
    mirror: true,
  });

  return (
    <div className="App">
      <Header></Header>
      <main className="wrapper">
        <Presentation></Presentation>
        <ActionsList></ActionsList>
        <Team></Team>
        <Contact></Contact>
        <News></News>
        <VideoPlayer></VideoPlayer>
      </main>
      <Footer></Footer>

      <FBPopup></FBPopup>
    </div>
  );
}

export default App;
