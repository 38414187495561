import React, { useState } from "react";
import "./actionsList.scss";
import allText from "../../assets/text.json"
import { FaAngleLeft } from "react-icons/fa";

function ActionsList() {
  const [textToShow, setTextToShow] = useState(0);

  function createMarkup(htmlContent) {
    return {__html: htmlContent};
  }
  
  function displayText(textId) {
    const idToString = `${textId}`
    const htmlContent = allText.find((text) => text.id === idToString)?.content
    console.log(htmlContent);
    if (htmlContent) {
      return <div className="text" dangerouslySetInnerHTML={createMarkup(htmlContent)} />;
    } else {
      return;
    }
  }

  function selectText(id) {
    if (id === textToShow) {
      setTextToShow(0)
    } else {
      setTextToShow(id)
    }
  }

  function computeClassName(id) {
    if (id === textToShow) {
      return 'selected'
    } else if (textToShow === 0) {
      return ''
    } else {
      return 'notSelected'
    }
  }

  return (
    <div className="ActionsList" id="actions">
      <div className="grid-item list-block content-block">
        <p onClick={() => selectText(4)} className={computeClassName(4)}>Maillages territoriaux : prévention des ruptures socio-scolaires</p>
        <p onClick={() => selectText(1)} className={computeClassName(1)}>Décrochage scolaire : adolescents vulnérables et scolarité</p>
        <p onClick={() => selectText(5)} className={computeClassName(5)}>Médiations éducatives et culturelles</p>
        <p onClick={() => selectText(6)} className={computeClassName(6)}>Pédagogies du sens et de l’explicite</p>
        <p onClick={() => selectText(3)} className={computeClassName(3)}>Alliances éducatives</p>
        <p onClick={() => selectText(2)} className={computeClassName(2)}>Relation éducative</p>
      </div>
      <div
        className="grid-item text-block content-block justify"
        data-aos="fade-right"
        data-aos-duration="800"
      >
        <h2> {textToShow === 0 || !textToShow ? 'Nos Actions' : 'Champs d’intervention'} </h2>
        {displayText(textToShow)}
        {(textToShow !== 0) && <div className="back" onClick={() => setTextToShow(0)}><FaAngleLeft></FaAngleLeft>revenir à "NOS ACTIONS"</div>}
      </div>
    </div>
  );
}

export default ActionsList;
